@import "flags/flags.css";
@import "tailwindcss/base";
@import "base/typography.css";
@import "tailwindcss/components";
@import "components/aside.css";
@import "components/button.css";
@import "components/badge.css";
@import "components/card.css";
@import "components/schema-form.css";
@import "components/scrollbar.css";
@import "components/input.css";
@import "components/modal.css";
@import "components/notification.css";
@import "tailwindcss/utilities";
@import "utilities/text.css";
@import "animations/animations.css";
@import "transitions/fade.css";
@import "themes/theme-base.css";
@import "themes/theme-default.css";