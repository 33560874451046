[class *= 'nation-flag'], [class *= 'nation-flag-bg'] {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

[class *= 'nation-flag'] {
    position: relative;
    display: inline-block;
    width: 1.333333em;
    line-height: 1em;
}
[class *= 'nation-flag']:before {
    content: " ";
}
[class *= 'nation-flag--square'] {
    width: 1em;
}

.nation-flag--cz {
    background-image: url(flag-icons/flags/4x3/cz.svg);
}
.nation-flag--cz.nation-flag--square {
    background-image: url(flag-icons/flags/1x1/cz.svg);
}

.nation-flag--de {
    background-image: url(flag-icons/flags/4x3/de.svg);
}
.nation-flag--de.nation-flag--square {
    background-image: url(flag-icons/flags/1x1/de.svg);
}

.nation-flag--sk {
    background-image: url(flag-icons/flags/4x3/sk.svg);
}
.nation-flag--sk.nation-flag--square {
    background-image: url(flag-icons/flags/1x1/sk.svg);
}

.nation-flag--gb {
    background-image: url(flag-icons/flags/4x3/gb.svg);
}
.nation-flag--gb.nation-flag--square {
    background-image: url(flag-icons/flags/1x1/gb.svg);
}