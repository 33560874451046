[class *= 'btn'] {
  @apply py-2 px-3.5;
  @apply inline-flex flex-row items-center justify-center gap-3;
  @apply border border-transparent;
  @apply focus:outline-none rounded-md;
}

.btn-icon {
  @apply px-2.5;
  @apply bg-white;
  @apply shadow-sm;
  @apply gap-0;
}

.btn-icon::after {
  content: '\200B';
}

.btn-white {
  @apply text-darkest-gray border-white bg-white
  hover:bg-light-gray
  disabled:text-gray-300 disabled:bg-white;
}

.btn-green {
  @apply text-white bg-green
  hover:bg-green-700
  disabled:bg-green-200;
}

.btn-outline-red {
  @apply text-red border border-red bg-white
  hover:bg-light-gray
  disabled:text-red-200 disabled:border-red-200 disabled:bg-white;
}

.btn-outline-green {
  @apply text-green border border-green bg-white
  hover:bg-light-gray
  disabled:text-green-200 disabled:border-green-200 disabled:bg-white;
}

.btn-outline-gray {
  @apply text-gray border border-gray bg-white
  hover:bg-light-gray
  disabled:text-gray-200 disabled:border-gray-200 disabled:bg-white;
}

.btn-outline-darkest-gray {
  @apply text-darkest-gray border border-darkest-gray bg-white
  hover:bg-light-gray
  disabled:text-gray-300 disabled:border-gray-300 disabled:bg-white;
}

.btn-xs {
  @apply py-1 px-2;
}

.btn-lg {
  @apply py-3 px-5;
}

.btn-xl {
  @apply py-2.5 px-5;
  @apply typo-subtitle-2;
}

/*.btn-sm {*/
/*  @apply py-1 px-2 text-xs;*/
/*}*/

/*.btn-disabled {*/
/*  @apply border-0 bg-basic text-white pointer-events-none;*/
/*}*/
