.card-rounding-t {
  @apply rounded-t-lg;
}

.card-rounding-b {
  @apply rounded-b-lg;
}

.card-rounding {
  @apply card-rounding-t;
  @apply card-rounding-b;
}

.card-shadow {
  @apply shadow-sm;
}

.card-bg {
  @apply bg-white;
}

.card-padding {
  @apply p-6;
}

.card {
  @apply card-rounding;
  @apply card-shadow;
  @apply card-bg;
  @apply card-padding;
}
