@layer utilities {
  .typo-h1 {
    @apply text-6xl;
    font-weight: 700;
    line-height: 60px;
  }

  .typo-h2 {
    @apply text-5xl;
    font-weight: 700;
    line-height: 47.5px;
  }

  .typo-h3 {
    @apply text-4xl;
    font-weight: 700;
    line-height: 38.75px;
  }

  .typo-h4 {
    @apply text-3xl;
    font-weight: 700;
    line-height: 31.25px;
  }

  .typo-subtitle-1 {
    @apply text-2xl;
    font-weight: 700;
    line-height: 25px;
  }

  .typo-subtitle-2 {
    @apply text-xl;
    font-weight: 700;
    line-height: 22.5px;
  }

  .typo-body-1 {
    @apply text-lg;
    font-weight: 500;
    line-height: 20px;
  }

  .typo-body-2 {
    @apply text-lg;
    font-weight: 400;
    line-height: 20px;
  }

  .typo-body-3 {
    @apply text-base;
    font-weight: 400;
    line-height: 20px;
  }

  .typo-placeholder {
    @apply text-base;
    font-weight: 400;
    line-height: 17.5px;
  }

  .typo-caption {
    @apply text-sm;
    font-weight: 500;
    line-height: 15px;
  }
}
