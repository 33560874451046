<template>
  <div
    class="markdown-content"
    v-html="renderContent"
  ></div>
</template>

<script lang="ts">
import MarkdownIt from 'markdown-it';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'MarkdownContent',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const md = MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    });

    const renderContent = computed(() => {
      if (!props.content) {
        return '';
      }

      try {
        return md.render(props.content);
      } catch (error) {
        console.error('Error rendering markdown content:', error);
        return '';
      }
    });


    return {
      renderContent,
    };
  },
});
</script>

<style scoped>
/* Scoped styles for the component */

/* Use :deep() to target elements inside the v-html rendered content */
.markdown-content :deep(p) {
  margin-bottom: 1em;
  line-height: 1.6;
}

.markdown-content :deep(h1),
.markdown-content :deep(h2),
.markdown-content :deep(h3),
.markdown-content :deep(h4),
.markdown-content :deep(h5),
.markdown-content :deep(h6) {
  font-weight: bold;
  margin: 1em 0 0.5em;
}

.markdown-content :deep(h1) {
  font-size: 1.75em;
}

.markdown-content :deep(h2) {
  font-size: 1.5em;
}

.markdown-content :deep(h3) {
  font-size: 1.25em;
}

.markdown-content :deep(h4) {
  font-size: 1.1em;
}

.markdown-content :deep(a) {
  color: #3b82f6;
  text-decoration: underline;
}

.markdown-content :deep(a:hover) {
  color: #2563eb;
}

.markdown-content :deep(ul),
.markdown-content :deep(ol) {
  padding-left: 2em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.markdown-content :deep(ul) {
  list-style-type: disc;
}

.markdown-content :deep(ol) {
  list-style-type: decimal;
}

.markdown-content :deep(ul ul) {
  list-style-type: circle;
}

.markdown-content :deep(ul ul ul) {
  list-style-type: square;
}

.markdown-content :deep(ol ol) {
  list-style-type: lower-alpha;
}

.markdown-content :deep(ol ol ol) {
  list-style-type: lower-roman;
}

.markdown-content :deep(ul ul),
.markdown-content :deep(ol ol),
.markdown-content :deep(ul ol),
.markdown-content :deep(ol ul) {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.markdown-content :deep(li) {
  margin-bottom: 0.5em;
  display: list-item;
}

.markdown-content :deep(pre),
.markdown-content :deep(code) {
  background-color: #f3f4f6;
  border-radius: 0.25rem;
  padding: 0.2em 0.4em;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.markdown-content :deep(pre) {
  padding: 1em;
  margin: 1em 0;
  overflow-x: auto;
  border: 1px solid #e5e7eb;
}

.markdown-content :deep(pre code) {
  background-color: transparent;
  padding: 0;
  border: none;
}

.markdown-content :deep(blockquote) {
  border-left: 4px solid #e5e7eb;
  padding-left: 1em;
  margin-left: 0;
  color: #6b7280;
  margin-bottom: 1em;
  margin-top: 1em;
}

/* Table styles */
.markdown-content :deep(table) {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.markdown-content :deep(th),
.markdown-content :deep(td) {
  border: 1px solid #e5e7eb;
  padding: 0.5em 0.75em;
  text-align: left;
}

.markdown-content :deep(th) {
  background-color: #f9fafb;
  font-weight: bold;
}

.markdown-content :deep(tr:nth-child(even)) {
  background-color: #f9fafb;
}

/* Horizontal rule */
.markdown-content :deep(hr) {
  border: 0;
  border-top: 1px solid #e5e7eb;
  margin: 1.5em 0;
}

/* Images */
.markdown-content :deep(img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em auto;
  border-radius: 0.25rem;
}

/* Definition lists */
.markdown-content :deep(dl) {
  margin: 1em 0;
}

.markdown-content :deep(dt) {
  font-weight: bold;
  margin-top: 0.5em;
}

.markdown-content :deep(dd) {
  margin-left: 2em;
  margin-bottom: 0.5em;
}

/* Strong and emphasis */
.markdown-content :deep(strong) {
  font-weight: bold;
}

.markdown-content :deep(em) {
  font-style: italic;
}

/* Strikethrough */
.markdown-content :deep(del),
.markdown-content :deep(s) {
  text-decoration: line-through;
}

/* Subscript and superscript */
.markdown-content :deep(sub),
.markdown-content :deep(sup) {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.markdown-content :deep(sup) {
  top: -0.5em;
}

.markdown-content :deep(sub) {
  bottom: -0.25em;
}

/* Syntax highlighting - basic colors for code blocks */
.markdown-content :deep(.hljs-keyword),
.markdown-content :deep(.hljs-tag) {
  color: #7c3aed; /* purple */
}

.markdown-content :deep(.hljs-string),
.markdown-content :deep(.hljs-attr) {
  color: #16a34a; /* green */
}

.markdown-content :deep(.hljs-comment) {
  color: #6b7280; /* gray */
  font-style: italic;
}

.markdown-content :deep(.hljs-function),
.markdown-content :deep(.hljs-name) {
  color: #2563eb; /* blue */
}

.markdown-content :deep(.hljs-number),
.markdown-content :deep(.hljs-literal) {
  color: #f59e0b; /* amber */
}

/* Reset other styles that might interfere */
.markdown-content :deep(*) {
  max-width: 100%;
}
</style>
