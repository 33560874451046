<template>
  <div style="all: revert;">
    <div
      v-if="loadingError"
      class="text-danger px-4"
    >
      {{ loadingError }}
    </div>
    <div
      v-else-if="loading"
      class="px-4"
    >
      {{ $t('layout.components.monacoEditor.loading') }}
    </div>
    <div
      ref="monacoRef"
      :class="[{'monaco-editor--readonly': readonly}]"
      v-bind="$attrs"
    >
    </div>
  </div>
</template>

<script lang="ts">
import Queue from '@ui/helpers/async';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  Ref,
  ref,
  toRaw,
  watch,
} from 'vue';

export default defineComponent({
  name: 'MonacoEditor',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: null,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<{ [key: string]: any }>,
      default: null,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props, {emit}) {
    const monacoRef = ref(null) as Ref<HTMLDivElement>;
    let monaco: any = null;
    const loading = ref(false);
    const loadingError = ref(null);
    let shadowRoot: ShadowRoot = null;
    const queue = new Queue();

    let editor: ReturnType<typeof monaco.editor['create']> = null;

    const disposeEditor = () => {
      editor.getModel().dispose();
      editor.dispose();
      shadowRoot?.replaceChildren();
    };

    const initEditor = () => queue.enqueue(async () => {
      if (editor) {
        disposeEditor();
      }

      const editorRoot = document.createElement('div');
      const editorStyles = document.createElement('style');

      editorRoot.style.height = monacoRef.value.style.height || '50vh';

      editorStyles.textContent = require('!raw-loader!monaco-editor/min/vs/editor/editor.main.css').default;

      editorRoot.style.width = '100%';

      shadowRoot = shadowRoot ?? monacoRef.value.attachShadow({mode: 'open'});

      shadowRoot.append(editorStyles);
      shadowRoot.append(editorRoot);

      try {
        loading.value = true;
        loadingError.value = null;
        monaco = await import('monaco-editor');
        emitTestEvent(TestEvent.monacoEditorLoaded);
      } catch (e) {
        console.error(e);
        loadingError.value = e.message;
      } finally {
        loading.value = false;
      }

      if (props.schema) {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: true,
          enableSchemaRequest: true,
          schemas: [
            {
              uri: props.schema.$schema ?? props.schema.schema,
              fileMatch: ['*'],
              schema: toRaw(props.schema),
            },
          ],
        });
      }

      editor = monaco.editor.create(editorRoot, {
        useShadowDOM: true,
        value: props.modelValue?.toString() ?? '',
        language: 'json',
        theme: 'vc-light',
        readOnly: props.readonly,
        tabSize: 2,
      });


      editor.onDidChangeModelContent(() => {
        if (props.modelValue !== editor.getValue()) {
          emit('update:modelValue', editor.getValue());
        }
      });

      editor.onDidFocusEditorWidget(() => {
        emit('focus');
      });

      editor.onDidBlurEditorWidget(() => {
        emit('blur');
      });
    });

    onMounted(() => {
      initEditor();
    });

    onBeforeUnmount(() => {
      disposeEditor();
    });

    watch([() => props.schema, () => props.readonly], () => {
      initEditor();
    });

    watch(() => props.modelValue, (modelValue: string) => {
      if (editor && modelValue !== editor.getValue()) {
        editor.setValue(modelValue);
      }
    });


    return {
      monacoRef,
      loading,
      loadingError,
    };
  },
});
</script>
<style>
  .monaco-editor--readonly { /* the up most container of the editor */
    filter: opacity(.8) saturate(.5) brightness(.96);
  }
</style>
