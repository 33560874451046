.aside {
    @apply w-full;
}

.aside:not(.aside--collapsed) {
    @apply fixed inset-0
}

.aside-header__logo {
    @apply w-16;
}

@screen lg {
    .aside {
        width: 90px;
    }

    .aside-header__logo {
        width: 60px;
    }

    .aside:not(.aside--collapsed) {
        width: 240px;
        @apply static inset-auto;
    }
}
