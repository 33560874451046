.animate-shimmer-placeholder {
  @apply relative;
  @apply bg-light-gray;

  background: linear-gradient(to right, #eeeeee 0%, #dddddd 35%, #eeeeee 70%);
  background-size: 50% 100%;

  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer-placeholder-keyFrame;
  animation-timing-function: linear;
}


@keyframes shimmer-placeholder-keyFrame {
  0%{
    background-position: -50% 0;
  }
  100%{
    background-position: 50% 0;
  }
}


.animate-background {
  animation: gradient 5s linear;
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    rotate: 0;
  }
  100% {
    rotate: 360deg;
  }
}

.animate-tilt-n-move-shaking {
  animation: tilt-n-move-shaking 0.55s infinite;
}

@keyframes tilt-n-move-shaking {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(3px, 3px) rotate(3deg); }
  50% { transform: translate(0, 0) rotate(0deg); }
  75% { transform: translate(-3px, 3px) rotate(-3deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.animate-going-up {
  animation: going-up 0.55s infinite;
}

@keyframes going-up {
  0% { transform: translate(0, 0); }
  25% { transform: translate(0, -6px); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, 2px); }
  100% { transform: translate(0, 0); }
}


.animate-going-down {
  animation: going-down 0.55s infinite;
}

@keyframes going-down {
  0% { transform: translate(0, 0); }
  75% { transform: translate(0, 2px); }
  50% { transform: translate(0, 0); }
  25% { transform: translate(0, -6px); }
  100% { transform: translate(0, 0); }
}