.notification-container {
  @apply fixed z-10 inset-0;
  @apply overflow-y-auto overflow-x-hidden;
  @apply pointer-events-none;
}

.notification {
  @apply flex flex-col items-center;
  @apply min-h-screen px-4 pb-20 sm:p-0;
}

.notification-content {
  @apply inline-block align-bottom transform transition-all sm:my-0 sm:align-middle sm:max-w-lg sm:w-full;
  @apply text-gray bg-white overflow-hidden;
  @apply rounded-lg;
  @apply pointer-events-auto;
}
