:root {
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.375rem;
  --font-size-3xl: 1.5rem;
  --font-size-4xl: 1.625rem;
  --font-size-5xl: 1.75rem;
  --font-size-6xl: 2rem;

  --icon-size-sm: var(--font-size-sm);
  --icon-size-base:var(--font-size-base);
  --icon-size-lg: var(--font-size-lg);
  --icon-size-xl: var(--font-size-xl);
  --icon-size-2xl:var(--font-size-2xl);
  --icon-size-3xl:var(--font-size-3xl);
  --icon-size-4xl:var(--font-size-4xl);
  --icon-size-5xl:var(--font-size-5xl);
  --icon-size-6xl:var(--font-size-6xl);
}

html {
  font-size: 12px;
}

@media screen(xl) {
  html {
    font-size: 14px;
  }
}
