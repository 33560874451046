.modal-container {
  @apply fixed z-10 inset-0;
  @apply overflow-y-auto overflow-x-hidden;
}

.modal {
  @apply flex flex-col items-center justify-center;
  @apply min-h-screen px-4 pb-20 sm:p-0;
  @apply py-20;
}

.modal-backdrop {
  @apply fixed inset-0 transition-opacity;
}

.modal-backdrop-content {
  @apply absolute inset-0;
  @apply bg-darkest-gray opacity-75;
}

.modal-content {
  @apply inline-block align-bottom transform transition-all sm:my-0 sm:align-middle sm:max-w-lg sm:w-full;
  @apply text-gray bg-white shadow-xl rounded-xl;
}

.modal-header {
  @apply flex justify-between items-center flex-row;
  @apply py-4 px-5;
  @apply bg-light-gray text-darkest-gray;
  @apply rounded-t-xl
}

.modal-body {
  @apply bg-white px-4 pt-4 pb-4 sm:p-6 sm:pb-4;
}

.modal-body:first-of-type {
  @apply rounded-t-xl
}

.modal-body:last-of-type {
  @apply rounded-b-xl
}

.modal-buttons {
  @apply bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between gap-3 shadow-2xl;
  @apply rounded-b-xl
}

.modal--sm .modal-content {
  @apply sm:max-w-sm
}

.modal--md .modal-content {
  @apply sm:max-w-md
}

.modal--xl .modal-content {
  @apply sm:max-w-xl
}

.modal--2xl .modal-content {
  @apply sm:max-w-2xl
}

.modal--3xl .modal-content {
  @apply sm:max-w-3xl
}

.modal--4xl .modal-content {
  @apply sm:max-w-4xl
}

.modal--5xl .modal-content {
  @apply sm:max-w-5xl
}

.modal--6xl .modal-content {
  @apply sm:max-w-6xl
}
