@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'DM Sans';
  src: url('@ui/assets/fonts/DMSans/DMSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
